<template>
  <div class="home">
    <section><img src="../assets/stew/image-1.jpg" /></section>
    <section><img src="../assets/stew/image-2.jpg" /></section>
    <section><img src="../assets/stew/image-3.jpg" /></section>
    <section><img src="../assets/stew/image-4.jpg" /></section>
    <section><img src="../assets/stew/image-5.jpg" /></section>
    <section><img src="../assets/stew/image-6.jpg" /></section>
    <section class="section7">
      <img src="../assets/stew/image-7.jpg" />
      <swiper :options="swiperOptions">
        <swiper-slide
          ><img src="../assets/stew/section7/section7-1.png"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/stew/section7/section7-2.png"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/stew/section7/section7-3.png"
        /></swiper-slide>
        <swiper-slide
          ><img src="../assets/stew/section7/section7-4.png"
        /></swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>
    <section><img src="../assets/stew/image-8.jpg" /></section>
    <section><img src="../assets/stew/image-9.jpg" /></section>
    <section class="section10">
      <img src="../assets/stew/image-10.jpg" />
      <div class="form">
        <input
          type="text"
          ref="name"
          placeholder="이름을 입력해주세요"
          v-model="name"
        />
        <input
          type="tel"
          ref="phone"
          placeholder="연락처를 입력해주세요"
          v-model="phone"
        />
        <input
          type="email"
          ref="email"
          placeholder="이메일 주소를 입력해주세요"
          v-model="email"
        />
        <input
          type="text"
          ref="area"
          placeholder="창업 희망 지역을 입력해주세요"
          v-model="area"
        />
        <input
          type="text"
          ref="visit_path"
          placeholder="방문 경로를 입력해주세요"
          v-model="visit_path"
        />

        <input type="checkbox" v-model="agree" />
        <div class="agree">[필수] 개인정보수집 이용 동의</div>
      </div>
      <button class="btn" @click="counselingRequest" />
    </section>
  </div>
</template>

<script>
import { counseling } from "@/api/counseling";

export default {
  name: "HomeView",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      area: "",
      visit_path: "",
      agree: false,
      swiperOptions: {
        loop: true, // 무한 루프
        autoplay: {
          delay: 5000, // 3초마다 자동으로 슬라이드 전환
          disableOnInteraction: false, // 사용자 상호작용 시 자동 재생 유지
        },
        centeredSlides: true, // 현재 슬라이드가 가운데에 위치하도록 설정
        slidesPerView: 1.3, // 한 번에 보이는 슬라이드 개수
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 클릭 가능하게 설정
        },
      },
    };
  },
  methods: {
    async counselingRequest() {
      if (this.name.length < 1) {
        alert("이름을 입력해주세요");
        this.$refs.name.focus();
        return;
      }

      if (this.phone.length < 1) {
        alert("연락처를 입력해주세요");
        this.$refs.phone.focus();
        return;
      }

      if (this.email.length < 1) {
        alert("이메일 주소를 입력해주세요");
        this.$refs.email.focus();
        return;
      }

      if (this.area.length < 1) {
        alert("창업 희망 지역을 입력해주세요");
        this.$refs.area.focus();
        return;
      }

      if (this.visit_path.length < 1) {
        alert("방문 경로를 입력해주세요");
        this.$refs.visit_path.focus();
        return;
      }

      if (!this.agree) {
        alert("개인정보수집 이용 동의에 체크 해주세요");
        return;
      }

      try {
        await counseling({
          domain: location.hostname,
          path: location.pathname,
          name: this.name,
          phone: this.phone,
          email: this.email,
          area: this.area,
          visit_path: this.visit_path,
        });
        alert("정상적으로 접수되었습니다. 감사합니다.");
        this.name = "";
        this.phone = "";
        this.email = "";
        this.area = "";
        this.visit_path = "";
        this.agree = false;
      } catch {
        alert("일시적인 오류입니다. 잠시후 다시 시도해주세요");
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  font-size: 0;

  .section7 {
    position: relative;

    .swiper-container {
      position: absolute;
      top: 30%;
      left: 50%;
      width: 100%;
      padding-bottom: 40px;
      transform: translateX(-50%);

      .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-pagination {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  .section10 {
    position: relative;
    font-size: 1rem;

    .form {
      position: absolute;
      top: 16.5%;
      left: 7%;
      width: 85.5%;
      height: 67%;

      input[type="text"],
      input[type="tel"],
      input[type="email"] {
        position: absolute;
        left: 15%;
        top: 11.5%;
        width: 70%;
        border: 0;
        box-sizing: border-box;
        background-color: transparent;

        &:nth-child(2) {
          top: calc(11.5% + 16.5%);
        }

        &:nth-child(3) {
          top: calc(11.5% + 16.5% + 16.4%);
        }

        &:nth-child(4) {
          top: calc(11.5% + 16.5% + 16.4% + 16%);
        }

        &:nth-child(5) {
          top: calc(11.5% + 16.5% + 16.4% + 16% + 16.2%);
        }
      }

      input[type="checkbox"] {
        position: absolute;
        top: 87.8%;
        left: 11.3%;
        width: 6.6vw;
        height: 6.6vw;
        appearance: none;
        background-color: transparent;
        background-image: url("../assets/stew/section10/chk_off.png");
        background-size: contain;

        &:checked {
          background-image: url("../assets/stew/section10/chk_on.png");
        }
      }

      .agree {
        position: absolute;
        top: 88.3%;
        left: 21%;
      }
    }

    .btn {
      position: absolute;
      top: 86%;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      height: 6.5%;
      border: 0;
      background-image: url("../assets/stew/section10/button.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
</style>
